<template>
  <wide-panel :title="title">
    <v-toolbar
      card
      extended
    >
      <v-container fluid>
        <v-layout row>
          <v-flex xs3>
            <v-select
              v-model="filter"
              :items="filters"
              item-text="text"
              item-value="value"
              label="Filter by"
            />
          </v-flex>
          <v-flex xs3>
            <v-select
              v-if="filter === 'rank'"
              v-model="rank"
              :items="ranks"
              item-text="text"
              item-value="value"
              label="Select rank"
            />
            <v-menu
              v-else-if="filter === 'dealership'"
              ref="dealershipMenu"
              v-model="dealershipMenu"
              :close-on-content-click="false"
              lazy
              transition="scale-transition"
              min-width="290px"
            >
              <v-text-field
                slot="activator"
                v-model="dealership"
                label="Select dealership date"
                persistent-hint
                append-icon="event"
                readonly
              />
              <v-date-picker
                ref="picker"
                v-model="dealership"
                :show-current="false"
                actions
                no-title
                @change="saveDealership"
              />
            </v-menu>
            <v-menu
              v-else-if="filter === 'subscription'"
              ref="subscriptionMenu"
              v-model="subscriptionMenu"
              :close-on-content-click="false"
              lazy
              transition="scale-transition"
              min-width="290px"
            >
              <v-text-field
                slot="activator"
                v-model="subscription"
                label="Select subscription date"
                persistent-hint
                append-icon="event"
                readonly
              />
              <v-date-picker
                ref="picker"
                v-model="subscription"
                :show-current="false"
                actions
                no-title
                @change="saveSubscription"
              />
            </v-menu>
            <v-text-field
              v-else-if="filter !== null && filter !== 'suspended'"
              v-model="search"
              label="Enter search string"
              clearable
            />
            <input
              v-if="filter === 'suspended'"
              v-model="defaultValue"
              type="hidden"
            >
          </v-flex>
          <v-flex xs5>
            <v-select
              v-model="selectedPlans"
              :items="plans"
              item-text="text"
              item-value="value"
              label="Mobile plan"
              multiple
              outlined
              return-object
            >
              <template #prepend-item>
                <v-list-tile
                  ripple
                  @click="toggle"
                >
                  <v-list-tile-action>
                    <v-icon
                      :color="
                        selectedPlans.length > 0 ? 'primary darken-2' : ''
                      "
                    >
                      {{ icon }}
                    </v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Select All</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider class="mt-2" />
              </template>
              <template #selection="{ item, index }">
                <v-chip v-if="index < 3">
                  <span>{{ item.text }}</span>
                </v-chip>
                <span
                  v-if="index === 3"
                  class="grey--text caption"
                >(+{{ selectedPlans.length - 3 }} others)</span>
              </template>
            </v-select>
          </v-flex>
          <v-flex xs1>
            <v-checkbox
              v-model="xox"
              label="XOX"
            />
          </v-flex>
        </v-layout>
      </v-container>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :pagination.sync="pagination"
          :total-items="totalItems"
          :rows-per-page-items="[10, 25, 50, 100]"
          :expand="true"
          item-key="id"
          class="px-1 pb-1"
        >
          <template #items="props">
            <tr @click="props.expanded = !props.expanded">
              <td
                v-if="props.item.name"
                class="text-xs-left"
              >
                {{ props.item.name }}
              </td>
              <td
                v-else
                class="text-xs-left"
              >
                -
              </td>
              <td
                v-if="props.item.nric"
                class="text-xs-left"
              >
                {{ props.item.nric }}
              </td>
              <td
                v-else
                class="text-xs-left"
              >
                -
              </td>
              <td
                v-if="props.item.msisdn"
                class="text-xs-left"
              >
                {{ props.item.msisdn }}
              </td>
              <td
                v-else
                class="text-xs-left"
              >
                -
              </td>
              <td
                v-if="props.item.subscriptionDate"
                class="text-xs-left"
              >
                {{ props.item.subscriptionDate }}
              </td>
              <td
                v-else
                class="text-xs-left"
              >
                -
              </td>
              <td
                v-if="props.item.status"
                class="text-xs-left"
              >
                {{
                  props.item.status.charAt(0).toUpperCase() +
                    props.item.status.slice(1)
                }}
              </td>
              <td
                v-else
                class="text-xs-left"
              >
                -
              </td>
              <td
                v-if="props.item.dealer"
                class="text-xs-left"
              >
                {{ props.item.dealer }}
              </td>
              <td
                v-else
                class="text-xs-left"
              >
                -
              </td>
              <td
                v-if="props.item.dealerRank"
                class="text-xs-left"
              >
                {{ props.item.dealerRank }}
              </td>
              <td
                v-else
                class="text-xs-left"
              >
                -
              </td>
              <td
                v-if="props.item.dealershipDate"
                class="text-xs-left"
              >
                {{ props.item.dealershipDate }}
              </td>
              <td
                v-else
                class="text-xs-left"
              >
                -
              </td>
              <td
                v-if="props.item.introducerId"
                class="text-xs-left"
              >
                {{ props.item.introducerId }}
              </td>
              <td
                v-else
                class="text-xs-left"
              >
                -
              </td>
            </tr>
          </template>
          <template #expand="props">
            <v-card>
              <v-container
                fluid
                grid-list-xs
              >
                <v-layout
                  row
                  wrap
                >
                  <v-flex
                    d-flex
                    xs2
                  >
                    <v-navigation-drawer
                      dark
                      stateless
                      permanent
                      floating
                      value="true"
                      class="indigo lighten-1"
                    >
                      <v-list dense>
                        <v-list-tile
                          v-if="canImpersonate"
                          @click="impersonate(props.item.id)"
                        >
                          <v-list-tile-content>
                            <v-list-tile-title class="orange--text">
                              Impersonate
                            </v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile @click="history(props.item.id)">
                          <v-list-tile-content>
                            <v-list-tile-title>
                              Support History
                            </v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile @click="note(props.item.id)">
                          <v-list-tile-content>
                            <v-list-tile-title>Create Note</v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                        <!--v-list-tile
                          v-if="canImpersonate"
                          @click="scrmsSync(props.item.id)">
                          <v-list-tile-content>
                            <v-list-tile-title>Sync with SCRMS</v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile-->
                        <v-list-tile
                          v-if="
                            props.item.status === 'active' ||
                              props.item.status === 'suspended'
                          "
                          @click.stop="suspend(props.item.id)"
                        >
                          <v-list-tile-content>
                            <v-list-tile-title color="orange">
                              {{
                                props.item.status === "suspended"
                                  ? "Unsuspend"
                                  : "Suspend"
                              }}
                            </v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile
                          v-if="canImpersonate"
                          @click="resetPassword(props.item.id)"
                        >
                          <v-list-tile-content>
                            <v-list-tile-title color="orange">
                              Reset Password
                            </v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile
                          @click="payoutMethodHistory(props.item.id)"
                        >
                          <v-list-tile-content>
                            <v-list-tile-title color="orange">
                              Payout Method History
                            </v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile @click="profileImages(props.item.id)">
                          <v-list-tile-content>
                            <v-list-tile-title color="orange">
                              Profile Images
                            </v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile @click="uplinesRecord(props.item.id)">
                          <v-list-tile-content>
                            <v-list-tile-title color="orange">
                              Upline
                            </v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile @click="consent3rdParty(props.item.id)">
                          <v-list-tile-content>
                            <v-list-tile-title color="orange">
                              Consent 3rd Party
                            </v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                      </v-list>
                    </v-navigation-drawer>
                  </v-flex>
                  <v-flex xs10>
                    <v-layout
                      row
                      wrap
                    >
                      <v-flex xs3>
                        Plan:
                      </v-flex>
                      <v-flex xs3>
                        <div v-if="props.item.isFamilyPlan">
                          <v-btn
                            color="primary"
                            @click="familyPlan(props.item)"
                          >
                            <span> {{ props.item.plan }} </span>
                          </v-btn>
                        </div>
                        <div v-if="props.item.isMySiswaPlan">
                          <v-btn
                            color="primary"
                            @click="mySiswaPlan(props.item)"
                          >
                            <span> {{ props.item.plan }} </span>
                          </v-btn>
                        </div>
                        <div v-else>
                          <span class="accent--text">
                            {{ props.item.plan }}
                          </span>
                        </div>
                      </v-flex>
                      <v-flex xs3>
                        UGU:
                      </v-flex>
                      <v-flex xs3>
                        <span class="accent--text">{{
                          props.item.ugu ? props.item.ugu : "-"
                        }}</span>
                      </v-flex>
                      <template v-if="props.item.dealer">
                        <v-flex xs3>
                          Tier-1 dealers:
                        </v-flex>
                        <v-flex xs3>
                          <span class="accent--text">{{
                            props.item.dealerCount
                          }}</span>
                        </v-flex>
                        <v-flex xs3>
                          Tier-1 subscribers:
                        </v-flex>
                        <v-flex xs3>
                          <span class="accent--text">{{
                            props.item.subscriberCount
                          }}</span>
                        </v-flex>
                        <v-flex xs3>
                          KPI start term:
                        </v-flex>
                        <v-flex xs3>
                          <span class="accent--text">{{
                            props.item.kpiStartTerm
                              ? props.item.kpiStartTerm
                              : "-"
                          }}</span>
                        </v-flex>
                        <v-flex xs3>
                          Next KPI term:
                        </v-flex>
                        <v-flex xs3>
                          <span class="accent--text">{{
                            props.item.nextKpiStartTerm
                              ? props.item.nextKpiStartTerm
                              : "-"
                          }}</span>
                        </v-flex>
                        <v-flex xs3>
                          Final payout month:
                        </v-flex>
                        <v-flex xs3>
                          <span class="accent--text">{{
                            props.item.finalPayoutMonth
                              ? props.item.finalPayoutMonth
                              : "-"
                          }}</span>
                        </v-flex>
                        <v-flex xs3>
                          E-mail:
                        </v-flex>
                        <v-flex xs3>
                          <span
                            class="accent--text"
                            v-html="email(props.item.email)"
                          />
                        </v-flex>
                        <v-flex xs3>
                          Area Dealer:
                        </v-flex>
                        <v-flex xs3>
                          <span
                            class="accent--text"
                            v-html="props.item.areaDealer"
                          />
                        </v-flex>
                        <v-flex xs3>
                          Master Dealer:
                        </v-flex>
                        <v-flex xs3>
                          <span
                            class="accent--text"
                            v-html="props.item.masterDealer"
                          />
                        </v-flex>
                      </template>
                      <v-flex xs3>
                        Address:
                      </v-flex>
                      <v-flex xs3>
                        <span
                          class="accent--text"
                          v-html="props.item.address"
                        />
                      </v-flex>
                      <v-flex xs3>
                        City:
                      </v-flex>
                      <v-flex xs3>
                        <span
                          class="accent--text"
                          v-html="props.item.city"
                        />
                      </v-flex>
                      <v-flex xs3>
                        Postcode:
                      </v-flex>
                      <v-flex xs3>
                        <span
                          class="accent--text"
                          v-html="props.item.postalCode"
                        />
                      </v-flex>
                      <v-flex xs3>
                        State:
                      </v-flex>
                      <v-flex xs3>
                        <span
                          class="accent--text"
                          v-html="props.item.state"
                        />
                      </v-flex>
                      <v-flex xs3>
                        Contact Number:
                      </v-flex>
                      <v-flex xs3>
                        <span
                          class="accent--text"
                          v-html="props.item.contact"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </template>
          <template slot="no-data">
            <v-alert
              :value="true"
              :type="error ? 'error' : 'info'"
            >
              {{ noDataText }}
            </v-alert>
          </template>
          <template slot="no-result">
            <v-alert
              :value="true"
              type="info"
            >
              {{ noResultText }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="suspendDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline"
          primary-title
        >
          {{
            activeItem && activeItem.status === "suspended"
              ? "Unsuspend"
              : "Suspend"
          }}
          {{
            activeItem
              ? activeItem.dealer
                ? activeItem.dealer
                : activeItem.msisdn
              : "Error"
          }}
        </v-card-title>
        <v-alert
          :value="alert"
          :type="alertType"
          transition="scale-transition"
        >
          {{ alertMessage }}
        </v-alert>
        <v-card-text>
          <v-textarea
            v-model="remark"
            hint="Enter your remark and reason to suspend/unsuspend."
            label="Remark"
            name="suspendRemark"
            box
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            flat
            @click="suspendDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            flat
            @click="suspendAccount()"
          >
            {{
              activeItem && activeItem.status === "suspended"
                ? "Unsuspend"
                : "Suspend"
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="createNoteDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline"
          primary-title
        >
          Create a note for
          {{
            activeItem
              ? activeItem.dealer
                ? activeItem.dealer
                : activeItem.msisdn
              : "Error"
          }}
        </v-card-title>
        <v-alert
          :value="alert"
          :type="alertType"
          transition="scale-transition"
        >
          {{ alertMessage }}
        </v-alert>
        <v-card-text>
          <v-textarea
            v-model="remark"
            hint="Enter a note."
            label="Note"
            name="note"
            box
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            flat
            @click="createNoteDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            flat
            @click="createNote()"
          >
            Create Note
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="supportHistoryDialog"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline"
          primary-title
        >
          Support history for
          {{
            activeItem
              ? activeItem.dealer
                ? activeItem.dealer
                : activeItem.msisdn
              : "Error"
          }}
        </v-card-title>
        <v-alert
          :value="alert"
          :type="alertType"
          transition="scale-transition"
        >
          {{ alertMessage }}
        </v-alert>
        <v-card-text>
          <v-data-table
            :headers="historyHeaders"
            :items="historyItems"
            class="elevation-1"
          >
            <template #items="props">
              <td class="text-xs-left">
                {{ props.item.entry }}
              </td>
              <td class="text-xs-left">
                {{ props.item.actorId }}
              </td>
              <td class="text-xs-left">
                {{ props.item.actor }}
              </td>
              <td class="text-xs-left">
                {{ props.item.action }}
              </td>
              <td class="text-xs-left">
                {{ props.item.remark }}
              </td>
            </template>
            <template slot="no-data">
              <v-alert
                :value="true"
                :type="error ? 'error' : 'info'"
              >
                {{ noDataText }}
              </v-alert>
            </template>
            <template slot="no-result">
              <v-alert
                :value="true"
                type="info"
              >
                {{ noResultText }}
              </v-alert>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            flat
            @click="supportHistoryDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="resetPasswordDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline"
          primary-title
        >
          Reset Password for
          {{
            activeItem
              ? activeItem.dealer
                ? activeItem.dealer
                : activeItem.msisdn
              : "Error"
          }}
        </v-card-title>
        <v-alert
          :value="alert"
          :type="alertType"
          transition="scale-transition"
        >
          {{ alertMessage }}
        </v-alert>
        <v-alert
          :value="!alert"
          type="info"
        >
          An SMS will be sent to the subscriber's number with a new random
          password.<br><br>
          IMPORTANT: Reseting password here will NOT reset the password for
          XOXID login.
        </v-alert>
        <v-card-text>
          <v-textarea
            v-model="remark"
            label="Remark"
            name="resetPasswordRemark"
            hint="Enter your remark and reason for reseting password."
            box
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            flat
            @click="resetPasswordDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            flat
            @click="resetSubscriberPassword()"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="payoutMethodHistoryDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline"
          primary-title
        >
          Payout Method History for
          {{
            activeItem
              ? activeItem.dealer
                ? activeItem.dealer
                : activeItem.msisdn
              : "Error"
          }}
        </v-card-title>
        <v-card-text><v-subheader>Last 20 entries</v-subheader></v-card-text>
        <v-alert
          :value="alert"
          :type="alertType"
          transition="scale-transition"
        >
          {{ alertMessage }}
        </v-alert>
        <v-card-text>
          <v-data-table
            :headers="payoutMethodHistoryHeaders"
            :items="payoutMethodHistoryItems"
            class="elevation-1"
          >
            <template #items="props">
              <td class="text-xs-left">
                {{ props.item.entry }}
              </td>
              <td class="text-xs-center">
                {{ props.item.method }}
              </td>
            </template>
            <template slot="no-data">
              <v-alert
                :value="true"
                :type="error ? 'error' : 'info'"
              >
                {{ noDataText }}
              </v-alert>
            </template>
            <template slot="no-result">
              <v-alert
                :value="true"
                type="info"
              >
                {{ noResultText }}
              </v-alert>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            flat
            @click="payoutMethodHistoryDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="profileImagesDialog"
      width="700"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline"
          primary-title
        >
          Profile Image for
          {{
            activeItem
              ? activeItem.dealer
                ? activeItem.dealer
                : activeItem.msisdn
              : "Error"
          }}
        </v-card-title>
        <v-alert
          :value="alert"
          :type="alertType"
          transition="scale-transition"
        >
          {{ alertMessage }}
        </v-alert>
        <v-card-text>
          <v-container
            grid-list-sm
            fluid
          >
            <v-layout
              row
              wrap
            >
              <v-flex
                xs12
                d-flex
              >
                <v-card
                  flat
                  tile
                  class="d-flex"
                >
                  <v-img
                    :src="nricImage"
                    :aspect-ratio="16 / 9"
                  >
                    <template #placeholder>
                      <v-layout
                        fill-height
                        align-center
                        justify-center
                        ma-0
                      >
                        <v-subheader v-if="noNricImage">
                          Profile Image not found
                        </v-subheader>
                        <v-progress-circular
                          v-else
                          indeterminate
                          color="grey lighten-5"
                        />
                      </v-layout>
                    </template>
                  </v-img>
                </v-card>
              </v-flex>
              <template v-if="images && images.length">
                <v-flex
                  xs12
                  d-flex
                >
                  <v-subheader>
                    Other possible profile images uploaded by the
                    dealer
                  </v-subheader>
                </v-flex>
                <v-flex
                  v-for="image in images"
                  :key="image"
                  xs12
                  d-flex
                >
                  <v-card
                    flat
                    tile
                    class="d-flex"
                  >
                    <v-img
                      :src="image"
                      :aspect-ratio="16 / 9"
                      class="rotateimg180"
                    >
                      <template #placeholder>
                        <v-layout
                          fill-height
                          align-center
                          justify-center
                          ma-0
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          />
                        </v-layout>
                      </template>
                    </v-img>
                  </v-card>
                </v-flex>
              </template>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            flat
            @click="profileImagesDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="consent3rdPartyDialog"
      width="500"
    >
      <v-card v-model="consent">
        <v-card-title
          class="headline"
          primary-title
        >
          Consent 3rdParty for
          {{
            activeItem
              ? activeItem.dealer
                ? activeItem.dealer
                : activeItem.msisdn
              : "Error"
          }}
        </v-card-title>
        <v-list-tile>
          <v-list-tile-avatar />
          <v-list-tile-content color="primary">
            <v-list-tile-title>Consent Letter</v-list-tile-title>
          </v-list-tile-content>
          <v-btn
            v-if="this.consentLetter"
            :href="consentLetter"
            target="_blank"
            flat
          >
            <v-icon
              small
              color="grey"
            >
              mdi-download
            </v-icon>
          </v-btn>
        </v-list-tile>
        <v-list-tile>
          <v-list-tile-avatar />
          <v-list-tile-content color="primary">
            <v-list-tile-title>
              Third Party Identification Card
            </v-list-tile-title>
          </v-list-tile-content>
          <v-btn
            v-if="this.thirdPartyIc"
            :href="thirdPartyIc"
            target="_blank"
            flat
          >
            <v-icon
              small
              color="grey"
            >
              mdi-download
            </v-icon>
          </v-btn>
        </v-list-tile>
        <v-list-tile>
          <v-list-tile-avatar />
          <v-list-tile-content color="primary">
            <v-list-tile-title>Witness's identification Card</v-list-tile-title>
          </v-list-tile-content>
          <v-btn
            v-if="this.witnessIc"
            :href="witnessIc"
            target="_blank"
            flat
          >
            <v-icon
              small
              color="grey"
            >
              mdi-download
            </v-icon>
          </v-btn>
        </v-list-tile>
        <v-list-tile>
          <v-list-tile-avatar />
          <v-list-tile-content color="primary">
            <v-list-tile-title>Letter of authorization</v-list-tile-title>
          </v-list-tile-content>
          <v-btn
            v-if="this.letterAuthorization"
            :href="letterAuthorization"
            target="_blank"
            flat
          >
            <v-icon
              small
              color="grey"
            >
              mdi-download
            </v-icon>
          </v-btn>
        </v-list-tile>
        <v-list-tile>
          <v-list-tile-avatar />
          <v-list-tile-content color="primary">
            <v-list-tile-title>Form 49,51,58</v-list-tile-title>
          </v-list-tile-content>
          <v-btn
            v-if="this.form59"
            :href="form59"
            target="_blank"
            flat
          >
            <v-icon
              small
              color="grey"
            >
              mdi-download
            </v-icon>
          </v-btn>
        </v-list-tile>
        <v-list-tile>
          <v-list-tile-avatar />
          <v-list-tile-content color="primary">
            <v-list-tile-title>SSM Document(s)</v-list-tile-title>
          </v-list-tile-content>
          <v-btn
            v-if="this.ssm"
            :href="ssm"
            target="_blank"
            flat
          >
            <v-icon
              small
              color="grey"
            >
              mdi-download
            </v-icon>
          </v-btn>
        </v-list-tile>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            flat
            @click="consent3rdPartyDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="uplineRecordDialog"
      width="1000"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline"
          primary-title
        >
          Dealer Tiers for
          {{
            activeItem
              ? activeItem.dealer
                ? activeItem.dealer
                : activeItem.msisdn
              : "Error"
          }}
        </v-card-title>
        <v-alert
          :value="alert"
          :type="alertType"
          transition="scale-transition"
        >
          {{ alertMessage }}
        </v-alert>
        <v-data-table
          :items="self"
          hide-actions
        >
          <template #items="props">
            <v-card class="pa-2">
              <v-layout
                row
                wrap.
              >
                <v-flex xs12>
                  <v-layout
                    row
                    wrap
                  >
                    <v-flex xs1>
                      Introducer:
                    </v-flex>
                    <v-flex xs3>
                      <span class="accent--text">{{ props.item.upline }}</span>
                      <br>
                      <span class="accent--text">{{ props.item.name1 }}</span>
                      <br>
                      <span class="accent--text">{{ props.item.msisdn1 }}</span>
                    </v-flex>
                    <v-flex xs1>
                      Tier2:
                    </v-flex>
                    <v-flex xs3>
                      <span class="accent--text">{{ props.item.tier2 }}</span>
                      <br>
                      <span class="accent--text">{{ props.item.name2 }}</span>
                      <br>
                      <span class="accent--text">{{ props.item.msisdn2 }}</span>
                    </v-flex>
                    <v-flex xs1>
                      Tier3:
                    </v-flex>
                    <v-flex xs3>
                      <span class="accent--text">{{ props.item.tier3 }}</span>
                      <br>
                      <span class="accent--text">{{ props.item.name3 }}</span>
                      <br>
                      <span class="accent--text">{{ props.item.msisdn3 }}</span>
                    </v-flex>
                    <v-flex xs1>
                      Tier4:
                    </v-flex>
                    <v-flex xs3>
                      <span class="accent--text">{{ props.item.tier4 }}</span>
                      <br>
                      <span class="accent--text">{{ props.item.name4 }}</span>
                      <br>
                      <span class="accent--text">{{ props.item.msisdn4 }}</span>
                    </v-flex>
                    <v-flex xs1>
                      Ad:
                    </v-flex>
                    <v-flex xs3>
                      <span class="accent--text">{{ props.item.ad }}</span>
                      <br>
                      <span class="accent--text">{{ props.item.name5 }}</span>
                      <br>
                      <span class="accent--text">{{ props.item.msisdn5 }}</span>
                    </v-flex>
                    <v-flex xs1>
                      Md:
                    </v-flex>
                    <v-flex xs3>
                      <span class="accent--text">{{ props.item.md }}</span>
                      <br>
                      <span class="accent--text">{{ props.item.name6 }}</span>
                      <br>
                      <span class="accent--text">{{ props.item.msisdn6 }}</span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </template>
        </v-data-table>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            flat
            @click="uplineRecordDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      color="success"
      top
    >
      {{ this.snackbarMessage }}
      <v-btn
        flat
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </wide-panel>
</template>

<script>
import { createGetParams, createPostParams } from '@/rest'
import store from '@/store'
import security from '@/security'
// import Jimp from 'jimp'

const reportTitle = 'Subscription List'

export default {
  components: {
    WidePanel: () =>
      import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel'),
  },
  data () {
    return {
      activeItem: null,
      alert: false,
      alertType: null,
      alertMessage: null,
      xox: null,
      createNoteDialog: false,
      defaultValue: null,
      dealership: null,
      error: null,
      filter: null,
      filters: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'NRIC',
          value: 'nric',
        },
        {
          text: 'MSISDN',
          value: 'msisdn',
        },
        {
          text: 'Rank',
          value: 'rank',
        },
        {
          text: 'Dealer Id',
          value: 'dealer',
        },
        {
          text: 'Dealership Date',
          value: 'dealership',
        },
        {
          text: 'Subscription Date',
          value: 'subscription',
        },
        {
          text: 'Suspended',
          value: 'suspended',
        },
      ],
      headers: [
        {
          align: 'left',
          sortable: false,
          text: 'Name',
          value: 'name',
        },
        {
          align: 'left',
          sortable: false,
          text: 'NRIC',
          value: 'nric',
        },
        {
          align: 'left',
          sortable: false,
          text: 'MSISDN',
          value: 'msisdn',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Subscription',
          value: 'subscriptionDate',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Status',
          value: 'status',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Dealer Id',
          value: 'dealer',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Rank',
          value: 'dealerRank',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Dealership',
          value: 'dealershipDate',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Introducer',
          value: 'introducerId',
        },
      ],
      historyHeaders: [
        {
          align: 'left',
          sortable: false,
          text: 'Entry',
          value: 'entry',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Actor ID',
          value: 'actorId',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Actor',
          value: 'actor',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Action',
          value: 'action',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Remark',
          value: 'remark',
        },
      ],
      historyItems: [],
      images: [],
      imageSrc: null,
      self: [],
      items: [],
      loading: false,
      dealershipMenu: false,
      noResultText: 'No data that fits that criteria.',
      nricImage: '',
      noNricImage: false,
      onex: true,
      selectedPlans: [],
      plans: [],
      pagination: {},
      consent3rdPartyDialog: false,
      payoutMethodHistoryDialog: false,
      payoutMethodHistoryHeaders: [
        {
          align: 'left',
          sortable: false,
          text: 'Entry',
          value: 'entry',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Method',
          value: 'method',
        },
      ],
      payoutMethodHistoryItems: [],
      uplineRecordDialog: false,
      profileImagesDialog: false,
      rank: null,
      ranks: [
        {
          text: 'Non-dealer',
          value: 'nondealer',
        },
        {
          text: 'Basic Dealer',
          value: 'b',
        },
        {
          text: 'Subdealer',
          value: 'sd',
        },
        {
          text: 'Dealer',
          value: 'dealer',
        },
        {
          text: 'Area Dealer',
          value: 'ad',
        },
        {
          text: 'Master Dealer',
          value: 'md',
        },
      ],
      consentLetter: null,
      form59: null,
      witnessIc: null,
      thirdPartyIc: null,
      letterAuthorization: null,
      ssm: null,
      consent: null,
      remark: null,
      resetPasswordDialog: false,
      snackbar: false,
      snackbarMessage: null,
      subscription: null,
      subscriptionMenu: false,
      supportHistoryDialog: false,
      suspendDialog: false,
      search: null,
      searchId: null,
      source: null,
      title: reportTitle,
      totalItems: 0,
      itemz: {},
    }
  },
  computed: {
    likesAllPlan () {
      return this.selectedPlans.length === this.plans.length
    },
    likesSomePlan () {
      return this.selectedPlans.length > 0 && !this.likesAllPlan
    },
    icon () {
      if (this.likesAllPlan) return 'mdi-close-box'
      if (this.likesSomePlan) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    canImpersonate: function () {
      return security.me.claimer() === 'onex' ? this.onex : !this.onex
    },
    noDataText: function () {
      return this.error ? this.error : "There's nothing to display."
    },
  },
  watch: {
    xox: function (val) {
      this.onex = !val
      this.refreshList()
    },
    filter: function () {
      (this.search || this.rank || this.dealership || this.subscription) &&
        this.resetList()
      this.search = null
      this.rank = null
      this.dealership = null
      this.subscription = null
      this.defaultValue = 'suspended'
    },
    defaultValue: function () {
      this.refreshList()
    },
    rank: function () {
      this.refreshList()
    },
    search: function (val) {
      clearTimeout(this.searchId)
      this.cancelRequest()
      if (val) {
        this.searchId = setTimeout(() => {
          this.refreshList()
        }, 1000)
      } else {
        this.resetList()
      }
    },
    selectedPlans: function (val) {
      if (val.length) {
        this.refreshList()
      } else {
        this.items = []
      }
    },
    dealership: function () {
      this.refreshList()
    },
    subscription: function () {
      this.refreshList()
    },
    pagination: {
      handler () {
        this.refreshList()
      },
      deep: true,
    },
  },
  mounted: function () {
    this.xox = security.me.claimer() === 'xox'
    this.getMobilePlans()
  },
  methods: {

    email (email) {
      return email ? '<a href=mailto:"' + email + '">' + email + '</a>' : '-'
    },
    async getMobilePlans () {
      try {
        const response = await this.$rest.get(
          'getMobilePlans.php',
          createGetParams({}),
        )
        this.selectedPlans = this.plans = response.data
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
    },
    payoutMethodHistory (id) {
      this.resetParameters(id)
      this.payoutMethodHistoryDialog = true
      this.getPayoutMethodHistory(id)
    },
    async getPayoutMethodHistory () {
      try {
        const params = createGetParams({
          id: this.activeItem.id,
          target: this.activeItem.claimer,
        })
        const response = await this.$rest.get(
          'getPayoutMethodHistory.php',
          params,
        )
        this.payoutMethodHistoryItems = response.data
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
    },

    async uplinesRecord (id) {
      this.resetParameters(id)
      this.uplineRecordDialog = true
      this.getuplinesRecord(id)
    },

    async getuplinesRecord () {
      try {
        const params = createGetParams({
          id: this.activeItem.id,
        })
        const response = await this.$rest.get('getUplines.php', params)
        this.self = response.data.self
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
    },

    async profileImages (id) {
      this.resetParameters(id)
      this.profileImagesDialog = true
      try {
        const params = createGetParams({
          msisdn: this.activeItem.msisdn,
        })
        const response = await this.$rest.get('getProfileImages.php', params)
        if (response.data.nric) {
          this.nricImage = response.data.nric
          this.noNricImage = false
          /*
          Jimp.read(response.data.nric).then(img => {
            this.imageSrc = img
            // eslint-disable-next-line handle-callback-err
            this.imageSrc.clone().rotate(90, true).getBase64(Jimp.MIME_JPEG, (err, src) => {
              this.nricImage = src
            })
          }).catch(err => {
            console.info(err)
          })
          */
        } else {
          this.nricImage = ''
          this.noNricImage = true
        }
        this.images = response.data.images
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
    },

    async getSubscriptionList () {
      const filters = this.getFilters()
      if (!filters) return { items: [], totalItems: 0 }

      this.loading = true
      const { page, rowsPerPage } = this.pagination
      const parameters = {
        page,
        rowsPerPage,
      }
      Object.assign(parameters, filters)

      const apiParameters = createGetParams(parameters)
      try {
        this.source = this.$rest.CancelToken.source()
        Object.assign(apiParameters, { cancelToken: this.source.token })
        const response = await this.$rest.get(
          'getSubscriptionList.php',
          apiParameters,
        )
        this.error = null
        this.loading = false
        return response.data
      } catch (error) {
        this.loading = false
        if (!this.$rest.isCancel(error)) {
          this.error = error.message
        }
        return { items: [], totalItems: 0 }
      }
    },

    async familyPlan (item) {
      let path = '/support/family/'
      path += item.msisdn
      this.$router.push({
        path,
      })
    },

    async mySiswaPlan (item) {
      let path = '/support/mysiswa/'
      path += item.msisdn
      this.$router.push({
        path,
      })
    },

    getFilters () {
      const filterBy = {}

      if (this.filter === 'msisdn' && this.search) {
        Object.assign(filterBy, { msisdn: this.search })
      }

      if (this.filter === 'dealer' && this.search) {
        Object.assign(filterBy, { dealer: this.search })
      }

      if (this.filter === 'name' && this.search) {
        Object.assign(filterBy, { name: this.search })
      }

      if (this.filter === 'nric' && this.search) {
        Object.assign(filterBy, { nric: this.search })
      }

      if (this.filter === 'rank' && this.rank) {
        Object.assign(filterBy, { rank: this.rank })
      }

      if (this.filter === 'dealership' && this.dealership) {
        Object.assign(filterBy, { dealershipDate: this.dealership })
      }

      if (this.filter === 'subscription' && this.subscription) {
        Object.assign(filterBy, { subscriptionDate: this.subscription })
      }

      if (this.filter === 'suspended') {
        Object.assign(filterBy, { status: this.defaultValue || 'suspended' })
      }
      Object.assign(filterBy, { onex: this.onex })

      const plans = []
      this.selectedPlans.forEach((plan) => {
        plans.push(plan.value)
      })
      Object.assign(filterBy, { plans })

      return this.filter &&
        (this.rank || this.search || this.dealership || this.subscription || (this.filter === 'suspended' && this.defaultValue))
        ? filterBy
        : null
    },
    cancelRequest: function () {
      this.loading = false
      if (this.source) {
        this.source.cancel('Forced cancellation.')
        this.source = null
      }
    },
    refreshList () {
      this.getSubscriptionList().then((data) => {
        this.items = data.items
        this.totalItems = data.totalItems
      })
    },
    resetList () {
      this.items = []
      this.totalItems = 0
    },
    saveDealership (date) {
      this.$refs.dealershipMenu.save(date)
    },
    saveSubscription (date) {
      this.$refs.subscriptionMenu.save(date)
    },
    async impersonate (id) {
      try {
        const params = createGetParams({
          id: id,
        })
        const response = await this.$rest.get('getImpersonatee.php', params)
        store.dispatch('impersonate', response.data)
        this.$router.push('/')
      } catch (error) {
        console.log('Error', error)
      }
    },
    showError (message) {
      this.alertType = 'error'
      this.alertMessage = message
      this.alert = true
    },
    resetParameters (id) {
      this.alert = false
      const item = this.items.find((item) => item.id === id)
      this.activeItem = item
      this.remark = null
      this.consent = this.activeItem.id
      this.nricImage = ''
      this.images = []
      this.historyItems = []
    },
    history (id) {
      this.resetParameters(id)
      this.supportHistoryDialog = true
      this.getSupportHistory()
    },
    note (id) {
      this.resetParameters(id)
      this.createNoteDialog = true
    },
    async createNote () {
      try {
        const params = createPostParams({
          action: 'createNote',
          payload: {
            id: this.activeItem.id,
            target: this.activeItem.claimer,
            note: this.remark,
          },
        })
        await this.$rest.post('postAction.php', params)
        this.createNoteDialog = false
        this.snackbarMessage = 'Note created.'
        this.snackbar = true
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
    },
    suspend (id) {
      this.resetParameters(id)
      this.suspendDialog = true
    },
    async suspendAccount () {
      try {
        const params = createPostParams({
          action:
            this.activeItem.status === 'active'
              ? 'suspendAccount'
              : 'unsuspendAccount',
          payload: {
            id: this.activeItem.id,
            target: this.activeItem.claimer,
            remark: this.remark,
          },
        })
        const response = await this.$rest.post('postAction.php', params)

        this.activeItem.status = response.data.status
        this.suspendDialog = false
        this.snackbarMessage =
          'Account ' +
          (this.activeItem.status === 'active' ? 'unsuspended.' : 'suspended.')
        this.snackbar = true
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
    },
    resetPassword (id) {
      this.resetParameters(id)
      this.resetPasswordDialog = true
    },
    consent3rdParty (id) {
      this.resetParameters(id)
      this.consent3rdPartyDialog = true
      this.getConsentLetter(id)
    },

    async getConsentLetter () {
      try {
        const params = createGetParams({
          id: this.activeItem.id,
        })
        const response = await this.$rest.get('getConsent3rdParty.php', params)
        this.consentLetter = response.data.consentLetter
        this.form59 = response.data.form59
        this.witnessIc = response.data.witnessIc
        this.thirdPartyIc = response.data.thirdPartyIc
        this.letterAuthorization = response.data.letterAuthorization
        this.ssm = response.data.ssm
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
    },

    async resetSubscriberPassword () {
      try {
        const params = createPostParams({
          action: 'resetPassword',
          payload: {
            id: this.activeItem.id,
            remark: this.remark,
          },
        })
        const response = await this.$rest.post('postAction.php', params)

        this.activeItem.status = response.data.status
        this.resetPasswordDialog = false
        this.snackbarMessage = 'Password reset completed.'
        this.snackbar = true
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
    },
    async getSupportHistory () {
      try {
        const params = createGetParams({
          id: this.activeItem.id,
          target: this.activeItem.claimer,
        })
        const response = await this.$rest.get('getSupportHistory.php', params)
        this.historyItems = response.data
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
    },
    toggle () {
      this.$nextTick(() => {
        if (this.likesAllPlan) {
          this.selectedPlans = []
        } else {
          this.selectedPlans = this.plans.slice()
        }
      })
    },
  },
}
</script>
